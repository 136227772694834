import { initializeTargeting } from 'BaxterScript/version/web/provider/InitializeTargeting';
import { creativeSources } from 'BaxterScript/version/web/provider/admanager/CreativeSources';
import * as Strings from 'BaxterScript/helper/string/String';
import * as Html from 'BaxterScript/helper/browser/Html';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { Config } from 'BaxterScript/types/Config';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import ninjaMetrics from 'BaxterScript/helper/metrics/NinjaMetrics';
import { NinjaMetric } from 'BaxterScript/helper/metrics/NinjaMetric';
import { AdManagerConfig, AdManagerSizesConfig } from 'BaxterScript/types/ProviderSlotConfig/AdManager';
import {
  AdManagerSlot,
  AdManagerInitializedSizesItem,
  AdMangerAdToShow,
  Callbacks,
  Initialized,
} from 'BaxterScript/types/Slot';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import { getTrackedParams } from 'BaxterScript/helper/targeting/Targeting';

interface AdManagerCreativePreview {
  hasPreview: boolean;
  previewAds: AdMangerAdToShow[];
}

export const id = Providers.AD_MANAGER;

const REGEX = /<script[^>]*>(?:[^<]+|<(?!\/script>))*<\/script>/gi;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const webpackExclude = (config: Config): boolean =>
  !(
    Object.values(config.slots.provider?._ ?? {}).includes(id) ||
    Object.values(config.slots.provider ?? {}).includes(id)
  );

const initializeSizes = (sizes: (string | number)[]): ({ width: number; height: number } | number)[] => {
  console.info('[SLOTS][ADMANAGER][INITIALIZESIZES]', sizes);
  const mappedSizes = sizes.map((item) => {
    if (Strings.isString(item) && (item as string).includes('x')) {
      const widthHeight = (item as string).split('x');
      const isIntegers = widthHeight.every((element) => Strings.isNumeric(element));
      if (isIntegers) {
        return {
          width: parseInt(widthHeight[0], 10),
          height: parseInt(widthHeight[1], 10),
        };
      }
    }
    return item as number;
  });
  return mappedSizes.reverse();
};

const initializeTypeSizes = (
  type: string,
  sizesConfig: AdManagerSizesConfig,
  initializedSizes: AdManagerInitializedSizesItem[]
): void => {
  console.info('[SLOTS][ADMANAGER][INITIALIZETYPESIZES]', type, sizesConfig, initializedSizes);
  if (sizesConfig[type]?.enabled) {
    initializedSizes.push({
      type: type.toUpperCase(),
      dimensions: initializeSizes(sizesConfig[type].sizes),
    });
  }
};

export const initialize = (
  pageId: string,
  containerId: string,
  slotId: string,
  params: TargetingParams
): Initialized<AdManagerSlot> => {
  console.info('[SLOTS][ADMANAGER][INITIALIZE]', pageId, containerId, slotId, params);
  const slotConfig = globalThis.Baxter.config.slots?.providerSettings?.[id] as AdManagerConfig;
  const providerConfig = globalThis.Baxter.config.providers[id];
  const initializedTargeting = {
    targeting: {},
  };
  initializeTargeting(initializedTargeting, slotConfig, providerConfig?.settings, pageId, containerId, slotId, params);
  const core = getConfigById(slotConfig?.core || {}, pageId, containerId, slotId);
  let targeting = {};
  if (slotConfig?.targeting) {
    targeting = getConfigById(slotConfig?.targeting || {}, pageId, containerId, slotId);
  }
  let sizes = {};
  const initializedSizes = [];
  if (slotConfig?.sizes) {
    sizes = getConfigById(slotConfig?.sizes || {}, pageId, containerId, slotId);
    ['banner', 'native'].forEach((type) => {
      initializeTypeSizes(type, sizes, initializedSizes);
    });
  }
  return {
    [id]: {
      providerConfig,
      config: {
        core,
        targeting,
        sizes,
      },
      initialized: {
        targeting: initializedTargeting.targeting as TargetingParams,
        sizes: initializedSizes,
      },
      state: {},
    },
  };
};

const fetchAds = async (source: string, slot: AdManagerSlot): Promise<AdMangerAdToShow[]> => {
  console.info('[SLOTS][ADMANAGER][FETCHADS]', slot);
  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      account: slot[id].providerConfig.settings.accountId,
      ...(slot[id].initialized.targeting.sessionLong
        ? { cid: slot[id].initialized.targeting.sessionLong as string }
        : {}),
    },
    body: JSON.stringify({
      slotHash: slot[id].config.core.hash,
      adCount: slot[id].config.core.adCount,
      appId: `${globalThis.Baxter.config.accountId}${globalThis.Baxter.config.appId}`.toUpperCase(),
      types: slot[id].initialized.sizes,
      customCriteria: Object.fromEntries(
        Object.entries(slot[id].initialized.targeting).map(([k, v]) => [
          k.toLowerCase(),
          // eslint-disable-next-line no-nested-ternary
          Array.isArray(v)
            ? v.map((vv) => (typeof vv === 'string' ? vv.toLowerCase() : vv))
            : typeof v === 'string'
              ? v.toLowerCase()
              : v,
        ])
      ),
      ...(slot[id].initialized.targeting.search_engine_input
        ? { searchEngineInput: [slot[id].initialized.targeting.search_engine_input] }
        : {}),
    }),
  };
  const response = await fetch(slot[id].providerConfig.settings.adRequestUrl, request);
  if (response.ok) {
    return (await response.json()).data;
  }
  throw new Error(`[SLOTS] Failed to fetch ad ${response.status} ${response.statusText}`);
};

const track = async (trackingUrl: string, eventType: string, showId: string): Promise<Response> =>
  fetch(`${trackingUrl}/v1/${eventType}/${showId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'no-cors',
  });

const onClickHandler = (
  trackingUrl: string,
  showId: string,
  destinationUrl: string,
  parameters: Record<string, unknown> = {}
) => {
  let preventDoubleClick = false;
  let preventMultipleClickTrackingRequests = false;
  return async (event) => {
    try {
      event.preventDefault();
      console.info('[SLOTS][ADMANAGER][ONCLICKHANDLER]', trackingUrl, showId, destinationUrl);
      if (!preventDoubleClick) {
        preventDoubleClick = true;
        if (!preventMultipleClickTrackingRequests) {
          preventMultipleClickTrackingRequests = true;
          await track(trackingUrl, 'click', showId);
          ninjaMetrics.reportMetric(NinjaMetric.ADVERTISEMENT_CLICKED, {
            ...parameters,
          });
          newRelicMetrics.reportMetric(NewRelicMetric.AD_CLICKED, {
            ...parameters,
            providerId: id,
          });
        }
        window.open(destinationUrl, '_blank');
        preventDoubleClick = false;
      }
    } catch (e) {
      console.error('[SLOTS][ADMANAGER][ONCLICKHANDLER]', e);
      newRelicMetrics.reportError(NewRelicError.ADMANAGER_CLICK_HANDLER_ERROR, { message: (e as Error).message });
    }
  };
};

const renderAds = async (source: string, slot: AdManagerSlot, adsToShow: AdMangerAdToShow[]): Promise<void> => {
  console.info('[SLOTS][ADMANAGER][RENDERADS]', slot, adsToShow);
  const creative = JSON.parse(adsToShow[0].creative);
  const ninjaParameters = {
    ...getTrackedParams(slot.params),
    slot_hash: adsToShow[0].slotHash,
    destination_url: creative.destinationUrl,
    adunit_external_id: adsToShow[0].adUnitExternalId,
    ad_slot_id: slot.id,
    ad_request_source: source,
    provider_id: slot.provider,
    attr_offer_id: adsToShow[0].attrOfferId,
    attr_app_id: adsToShow[0].attrAppId,
    attr_seller_id: adsToShow[0].attrSellerId,
  };
  if (creative.type === 'BANNER') {
    const bannerId = `${slot.innerId}-banner`;
    // eslint-disable-next-line no-param-reassign
    slot.innerHtmlElement.innerHTML = `<a id="${bannerId}"><img src="${creative.url}" width="${creative.size[0]}" height="${creative.size[1]}" alt="Advertising"/></a>`;
    const image = Html.getElementById(bannerId);
    image?.addEventListener(
      'click',
      onClickHandler(
        slot[id].providerConfig.settings.adTrackingUrl,
        adsToShow[0].showId,
        creative.destinationUrl,
        ninjaParameters
      )
    );
    slot[id].callbacks.slotRenderEndedCallback(source, slot, false, undefined, ninjaParameters);
    await track(slot[id].providerConfig.settings.adTrackingUrl, 'view', adsToShow[0].showId);
  } else if (creative.type === 'NATIVE') {
    const htmlResponse = await fetch(creative.url);
    const html = await htmlResponse.text();
    if (slot[id].state.alreadyRemoved) {
      console.debug('[SLOTS][ADMANAGER][LOAD] slot already removed', slot);
      newRelicMetrics.reportMetric(NewRelicMetric.ADMANAGER_SLOT_ALREADY_REMOVED, { place: 'afterFetchCreative' });
      return;
    }
    // eslint-disable-next-line no-param-reassign
    slot.innerHtmlElement.innerHTML = html.replace(REGEX, '');
    const onClick = onClickHandler(
      slot[id].providerConfig.settings.adTrackingUrl,
      adsToShow[0].showId,
      creative.destinationUrl,
      ninjaParameters
    );
    const parser = document.createElement('div');
    parser.innerHTML = html;
    const scripts = parser.getElementsByTagName('script');
    [...scripts].forEach((js) => {
      const script = document.createElement('script');
      script.innerHTML =
        `try {\n${js.innerHTML}\n` +
        `} catch (err) {\n` +
        `  throw new Error('Advertising - Baxter SelfServe | AccountId: ${globalThis.Baxter.config.accountId} | PageId: ${slot.pageId} | ContainerId: ${slot.containerId} | Slot: ${slot.id} | ' + err);\n` +
        `}`;
      script.type = 'text/javascript';
      script.async = true;
      slot.innerHtmlElement.appendChild(script);
    });
    const elements = slot.innerHtmlElement.getElementsByClassName('baxter-destination-click-handler');
    if (elements) {
      for (const element of elements) {
        element.addEventListener('click', onClick);
      }
    }
    slot[id].callbacks.slotRenderEndedCallback(source, slot, false, undefined, ninjaParameters);
    await track(slot[id].providerConfig.settings.adTrackingUrl, 'view', adsToShow[0].showId);
  } else {
    newRelicMetrics.reportError(NewRelicError.ADMANAGER_INVALID_CREATIVE_TYPE, { type: creative.type });
    slot[id].callbacks.slotRenderEndedCallback(source, slot, true);
  }
};

const creativePreview = (slot: AdManagerSlot): AdManagerCreativePreview => {
  console.info('[SLOTS][ADMANAGER][CREATIVEPREVIEW]', slot);
  const preview = {
    hasPreview: false,
    previewAds: [],
  } as AdManagerCreativePreview;
  const query = window?.location?.search;
  if (!query || !slot) {
    return preview;
  }
  const urlParams = new URLSearchParams(query);
  const baxterCreativeUrl = urlParams.get('baxterCreativeUrl') ?? '';
  const baxterContainerId = urlParams.get('baxterContainerId') ?? '';
  const creativeUrl = decodeURIComponent(baxterCreativeUrl);
  const allowedSource = creativeSources.some((source) => creativeUrl.startsWith(source));
  if (!allowedSource) {
    console.debug('[SLOTS][ADMANAGER][CREATIVEPREVIEW] creative source path not allowed', creativeUrl);
    return preview;
  }
  preview.hasPreview = !!baxterContainerId && !!creativeUrl && baxterContainerId === slot.containerId;
  preview.previewAds = [
    {
      creative: JSON.stringify({ type: 'NATIVE', url: creativeUrl }),
      showId: '',
      slotHash: '',
      adUnitExternalId: '',
      attrOfferId: '',
      attrAppId: '',
      attrSellerId: '',
    },
  ];

  if (preview.hasPreview) {
    console.debug('[SLOTS][ADMANAGER][CREATIVEPREVIEW] hasPreview', baxterContainerId, creativeUrl);
  }
  return preview;
};

export const create = (slot: AdManagerSlot, callbacks: Callbacks): void => {
  console.info('[SLOTS][ADMANAGER][CREATE]', slot);
  // eslint-disable-next-line no-param-reassign
  slot[id].callbacks = callbacks;
};

export const load = async (source: string, slots: AdManagerSlot[] = []): Promise<void> => {
  console.info('[SLOTS][ADMANAGER][LOAD]', slots);
  await Promise.all(
    slots.map(async (slot) => {
      try {
        if (slot[id].state.alreadyRemoved) {
          console.debug('[SLOTS][ADMANAGER][LOAD] slot already removed', slot);
          newRelicMetrics.reportMetric(NewRelicMetric.ADMANAGER_SLOT_ALREADY_REMOVED, { place: 'load' });
          return;
        }
        const { hasPreview, previewAds } = creativePreview(slot);
        const adsToShow = hasPreview ? previewAds : await fetchAds(source, slot);
        if (slot[id].state.alreadyRemoved) {
          console.debug('[SLOTS][ADMANAGER][LOAD] slot already removed', slot);
          newRelicMetrics.reportMetric(NewRelicMetric.ADMANAGER_SLOT_ALREADY_REMOVED, { place: 'afterFetchAds' });
          return;
        }
        if (adsToShow?.length) {
          await renderAds(source, slot, adsToShow);
        } else {
          slot[id].callbacks.slotRenderEndedCallback(source, slot, true);
        }
      } catch (err) {
        console.error('[SLOTS][ADMANAGER][LOAD]', err);
        newRelicMetrics.reportError(NewRelicError.ADMANAGER_LOAD_ERROR, { message: (err as Error).message });
      }
    })
  );
};

export const remove = (slots: AdManagerSlot[]): void => {
  console.info('[SLOTS][ADMANAGER][REMOVE]', slots);
  slots.forEach((slot) => {
    // eslint-disable-next-line no-param-reassign
    slot[id].state.alreadyRemoved = true;
    Html.clear(slot.innerHtmlElement);
  });
};
